import React, { useState } from "react"
import PropTypes from "prop-types"
import Image from "../components/image"

import axios from "axios"
import { css } from "@emotion/core"
import { useForm } from "react-hook-form"
import { regEmail } from "../utils/utils"
import { RichText } from "prismic-reactjs"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"

const FormSlice = ({ slice }) => {
  const [formSent, setFormSent] = useState("")
  const { register, handleSubmit, errors } = useForm()
  const { executeRecaptcha } = useGoogleReCaptcha()

  const onSubmit = async formData => {
    setFormSent({ sending: true })
    const captchaToken = await executeRecaptcha("homepage")
    axios
      .post("/.netlify/functions/contactForm", { formData, captchaToken })
      .then(function(response) {
        setFormSent(response)
      })
      .catch(function(error) {
        setFormSent({ error: error.message })
      })
  }
  return (
    <div className="section" css={leadFormCss}>
      <div className="columns is-centered is-vcentered">
        {slice.primary.form_image && (
          <div className="column">
            <Image
              sharp={slice.primary.form_imageSharp}
              image={slice.primary.form_image}
            />
          </div>
        )}
        <div className="column is-8">
          <div className="box">
            <div className="title is-5">
              {RichText.asText(slice.primary.form_title)}
            </div>
            <form
              key={slice.primary.form_id}
              onSubmit={handleSubmit(onSubmit)}
              className="form__outline "
            >
              <input
                className="input"
                type="hidden"
                name="lead"
                value={slice.primary.form_id}
                ref={register}
              />

              {slice.fields.map(field => (
                <div key={field.form_field_id} className="field">
                  <label className="label" htmlFor={field.form_field_id}>
                    {field.form_field_name}
                  </label>
                  <div className="control">
                    {field.form_field_type === "text" && (
                      <input
                        className={`input ${
                          errors[field.form_field_id] ? "is-danger" : ""
                        }`}
                        type="text"
                        name={field.form_field_id}
                        id={field.form_field_id}
                        placeholder={
                          field.form_field_placeholder &&
                          field.form_field_placeholder
                        }
                        ref={register({
                          required: field.field_is_required ? true : false,
                        })}
                      />
                    )}

                    {field.form_field_type === "email" && (
                      <input
                        className={`input ${
                          errors[field.form_field_id] ? "is-danger" : ""
                        }`}
                        type="email"
                        name={field.form_field_id}
                        id={field.form_field_id}
                        placeholder={
                          field.form_field_placeholder &&
                          field.form_field_placeholder
                        }
                        ref={register({
                          pattern: regEmail,
                          required: field.field_is_required ? true : false,
                        })}
                      />
                    )}

                    {field.form_field_type === "textarea" && (
                      <textarea
                        name={field.form_field_id}
                        id={field.form_field_id}
                        rows="1"
                        className={`textarea ${
                          errors[field.form_field_id] ? "is-danger" : ""
                        }`}
                        placeholder={field.form_field_placeholder}
                        ref={register({
                          required: field.field_is_required ? true : false,
                        })}
                      ></textarea>
                    )}

                    {field.form_field_type === "list" && (
                      <div className="control">
                        <div className="select">
                          <select
                            name={field.form_field_id}
                            ref={register({
                              required: field.field_is_required ? true : false,
                            })}
                          >
                            {field.form_field_list.split(",").map(item => (
                              <option>{item}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                    )}

                    {(field.form_field_type === "radio" ||
                      field.form_field_type === "checkbox") && (
                      <div className="control">
                        {field.form_field_list.split(",").map((item, i) => (
                          <label key={i} className={field.form_field_type}>
                            <input
                              type={field.form_field_type}
                              name={field.form_field_id}
                              value={item}
                              id={field.form_field_id}
                              ref={register({
                                required: field.field_is_required
                                  ? true
                                  : false,
                              })}
                            />
                            {item}
                          </label>
                        ))}
                      </div>
                    )}
                    {errors[field.form_field_id] && (
                      <p className="help is-danger">This field is required</p>
                    )}
                  </div>
                </div>
              ))}

              <div className="field pt-5">
                <div className="control">
                  {formSent === "" && (
                    <button className="button is-rounded button__primary">
                      {slice.primary.form_button}
                      <span className="icon-arrow_right pl-2"></span>
                    </button>
                  )}
                  {formSent && formSent.sending && (
                    <button className="button is-rounded" disabled>
                      ...
                    </button>
                  )}
                  {formSent.status && formSent.status === 200 && (
                    <button className="button" title="Disabled button" disabled>
                      {slice.primary.form_success_message}
                    </button>
                  )}
                  {formSent && formSent.error && (
                    <div className="notification is-rounded is-danger">
                      {formSent.error}
                    </div>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FormSlice

FormSlice.propTypes = {
  slice: PropTypes.object.isRequired,
}

const leadFormCss = css`
  img {
    min-width: 300px;
  }
`
