import React from "react"
import { RichText } from "prismic-reactjs"
import LocalizedLink from "./localizedLink"
import slugify from "slugify"

function IntegrationCategories({
  allIntegrationsData,
  integrationsPageData,
  active,
}) {
  let allCategories = new Set()
  let allFeatures = new Set()
  allIntegrationsData.edges.map(edge =>
    edge.node._meta.tags.map(tag =>
      tag.startsWith("feature")
        ? allFeatures.add(tag.substring(8))
        : allCategories.add(tag)
    )
  )
  return (
    <>
      <aside className="menu">
        <h3 className="menu-label title is-5 is-capitalized">
          {RichText.asText(integrationsPageData.title_categories)}
        </h3>
        <ul className="menu-list">
          <li
            css={
              active === undefined
                ? { fontWeight: "bold", textDecoration: "underline" }
                : ""
            }
          >
            <LocalizedLink to={`integrations/`}>
              {integrationsPageData.all}
            </LocalizedLink>
          </li>
          {Array.from(allCategories).map(category => {
            return (
              <li
                key={category}
                css={
                  category === active
                    ? { fontWeight: "bold", textDecoration: "underline" }
                    : ""
                }
              >
                <LocalizedLink
                  to={`integration/tag/${slugify(category)}#jumpToIntegration`}
                >
                  {category}
                </LocalizedLink>
              </li>
            )
          })}
        </ul>
      </aside>
    </>
  )
}

export default IntegrationCategories
