import React, { useState } from "react"
import PropTypes from "prop-types"
import { graphql, navigate } from "gatsby"
import { RichText } from "prismic-reactjs"
import { css } from "@emotion/core"
import { colors, mq } from "../styles/styles"
import SEO from "../components/SEO"
import Hero from "../components/hero"
import Trial from "../components/trial"
import CardIntegration from "../components/cardIntegration"
import IntegrationCategories from "../components/integrationCategories"
import LocalizedLink from "../components/localizedLink"
import locales from "../../config/i18n"

import SliceForm from "../slices/form"
import { useForm } from "react-hook-form"
import { regEmail } from "../utils/utils"

const IntegrationsPage = ({
  data,
  pageContext: { locale, subPages },
  location,
}) => {
  const integrationsPageData = data.prismic.integrationspage
  const allIntegrationsData = data.prismic.allIntegrations
  // const allNewIntegrationsData = data.prismic.allNewIntegrations
  // const allFeaturedIntegrationsData = data.prismic.allFeaturedIntegrations
  // const allPopularIntegrationsData = data.prismic.allPopularIntegrations
  let allCategories = new Set()
  let allFeatures = new Set()
  const [filterSelected, setFilterSelected] = useState(false)
  const [searchResults, setSearchResults] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)

  // All integrations from Context (not limited to 20) Defining filtered lists
  const allIntegrationsInLang = []
  const allIntegrationFeatured = []
  const allIntegrationPopular = []
  subPages
    .filter(integration => integration.node._meta.lang === locale)
    .map(integration => allIntegrationsInLang.push(integration))

  const [integrationsToDisplay, setIntegrationsToDisplay] = useState(
    allIntegrationsInLang
  )

  allIntegrationsInLang
    .filter(integration => integration.node.is_featured === true)
    .map(integration => allIntegrationFeatured.push(integration))
  allIntegrationsInLang
    .filter(integration => integration.node.is_popular === true)
    .map(integration => allIntegrationPopular.push(integration))

  const handleSearchChange = e => {
    if (e.target.value.length < 2) {
      setSearchResults(false)
      setIntegrationsToDisplay(allIntegrationsInLang)
      return false
    }
    // filter with Name OR Id
    let results = allIntegrationsInLang.filter(
      integration =>
        integration.node.title[0].text
          .toLowerCase()
          .includes(e.target.value.toLowerCase())
      // ||
      // integration.func_id.includes(e.target.value)
    )
    setSearchResults(results)
    setIntegrationsToDisplay(results)
  }

  const handleKeyDown = e => {
    if (e.key === "Enter" && searchResults) {
      // when a result exist and ENTER is pressed, navigate to first result
      locales[locale].default === true
        ? navigate(`/integration/${searchResults[0].node._meta.uid}`)
        : navigate(
            `/${locales[locale].path}/integration/${searchResults[0].node._meta.uid}`
          )
    }
  }

  const handleFilterClick = filter => {
    setFilterSelected(filter)
    if (filter === "all") setIntegrationsToDisplay(allIntegrationsInLang)
    if (filter === "featured") setIntegrationsToDisplay(allIntegrationFeatured)
    if (filter === "popular") setIntegrationsToDisplay(allIntegrationPopular)
    if (filter === "new") setIntegrationsToDisplay(allIntegrationsData.edges)
  }

  return (
    <>
      <SEO
        title={integrationsPageData.meta_title}
        desc={integrationsPageData.meta_description}
        metadata={data.site.siteMetadata}
        locale={locale}
        pathname={location.pathname}
      />
      <Hero
        hero={{
          title: integrationsPageData.title,
          subtitle: integrationsPageData.subtitle,
          image: {
            image: integrationsPageData.main_image,
            sharp: integrationsPageData.main_imageSharp,
          },
        }}
      >
        <div className="field form__outline mt-5 pb-5">
          <p className="control has-icons-left">
            <input
              className="input"
              placeholder={RichText.asText(integrationsPageData.title_search)}
              onChange={handleSearchChange}
              onKeyDown={handleKeyDown}
            />
            <span className="icon is-left">
              <i className="icon-magnifying_glass" />
            </span>
          </p>
        </div>
        {/*
        display integrations as list while searching
        {searchResults && (
          <ul
            css={{
              position: "absolute",
              background: "white",
              width: "20%",
              padding: "10px",
              zIndex: "10",
            }}
          >
            {searchResults.map((result, i) => (
              <li key={i}>
                <LocalizedLink to={`integration/${result.node._meta.uid}`}>
                  {result.node.title[0].text}
                </LocalizedLink>
                <hr />
              </li>
            ))}
          </ul>
        )} */}
      </Hero>

      <section className="section" css={integrationPageCss}>
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-narrow column__aside">
              <IntegrationCategories
                allIntegrationsData={{ edges: allIntegrationsInLang }}
                integrationsPageData={integrationsPageData}
              />
            </div>
            <div className="column is-9 column__main">
              <nav className="level">
                <div className="level-left py-2">
                  <div className="level-item mr-2">
                    <span className="title is-6">
                      {integrationsPageData.sort}
                    </span>
                  </div>
                  <div className="level-item mx-2">
                    <a
                      className={`button is-rounded is-outlined is-link is-small ${filterSelected ===
                        "popular" && "is-active"}`}
                      onClick={() => {
                        filterSelected === "popular"
                          ? handleFilterClick("all")
                          : handleFilterClick("popular")
                      }}
                    >
                      {integrationsPageData.popular}
                      <span className="icon-cross pl-2"></span>
                    </a>
                  </div>
                  <div className="level-item mx-2">
                    <a
                      className={`button is-rounded is-outlined is-link is-small ${filterSelected ===
                        "featured" && "is-active"}`}
                      onClick={() => {
                        filterSelected === "featured"
                          ? handleFilterClick("all")
                          : handleFilterClick("featured")
                      }}
                    >
                      {integrationsPageData.featured}
                      <span className="icon-cross pl-2"></span>
                    </a>
                  </div>
                  <div className="level-item mx-2">
                    <a
                      className={`button is-rounded is-outlined is-link is-small ${filterSelected ===
                        "new" && "is-active"}`}
                      onClick={() => {
                        filterSelected === "new"
                          ? handleFilterClick("all")
                          : handleFilterClick("new")
                      }}
                    >
                      {integrationsPageData.newest}
                      <span className="icon-cross pl-2"></span>
                    </a>
                  </div>
                </div>
                <div className="level-right py-2">
                  <div className="level-item">
                    <a
                      onClick={() => setIsModalOpen(true)}
                      className="button is-rounded has-background-white has-text-primary has-text-weight-medium button__gradient-border"
                    >
                      <span className="icon-plus_rounded pr-2"></span>
                      {integrationsPageData.request_integration_button}
                    </a>
                  </div>
                </div>
              </nav>
              {isModalOpen && (
                <div className="modal" css={{ display: "flex" }}>
                  <div
                    onClick={() => setIsModalOpen(false)}
                    className="modal-background"
                  ></div>
                  <div className="modal-card">
                    <section className="modal-card-body">
                      <SliceForm slice={integrationsPageData.body1[0]} />
                    </section>
                  </div>
                </div>
              )}

              <div className="columns is-multiline">
                {integrationsToDisplay &&
                  integrationsToDisplay.map(integration => {
                    return (
                      <div
                        key={integration.node._meta.uid}
                        className="column is-4-desktop is-6-tablet"
                      >
                        <CardIntegration integration={integration} />
                      </div>
                    )
                  })}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Trial trial={data.prismic.allTrialcomponents.edges[0].node} />
    </>
  )
}

export default IntegrationsPage

IntegrationsPage.propTypes = {
  pageContext: PropTypes.object.isRequired,
  data: PropTypes.shape({
    prismic: PropTypes.object.isRequired,
  }).isRequired,
}

export const IntegrationsPageQuery = graphql`
  query IntegrationsPageQuery($uid: String!, $locale: String!) {
    prismic {
      integrationspage: integrationspage(uid: $uid, lang: $locale) {
        _meta {
          lang
          uid
        }
        meta_title
        meta_description
        title
        subtitle
        title_search
        title_categories
        all
        sort
        popular
        featured
        newest
        request_integration_button
        main_image
        main_imageSharp {
          extension
          publicURL
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
        body1 {
          ... on PRISMIC_IntegrationspageBody1Form {
            primary {
              form_id
              form_title
              form_button
              form_success_message
            }
            fields {
              form_field_id
              form_field_name
              form_field_type
              form_field_placeholder
              form_field_list
              field_is_required
            }
          }
        }
      }
      allIntegrations: allIntegrations(
        lang: $locale
        sortBy: meta_firstPublicationDate_DESC
      ) {
        edges {
          node {
            _meta {
              uid
              tags
            }
            title
            is_featured
            is_popular
            main_image
            main_imageSharp {
              extension
              publicURL
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
          }
        }
      }

      ...Trial
    }
    site {
      ...SiteInformation
    }
  }
`

const integrationPageCss = css`

  .level-left .button span {
    display: none;
  }

  .level-left .button:hover span {
    display: block;
  }

  .button.is-rounded.is-outlined.is-link.is-active {
    background-color: #5769a4;
    border-color: #5769a4;
    color: #fff;
    span {
      display: block;
    }
  }

  @media (max-width: 768px) {
    .menu-list {
      display: inline-flex;
      flex-wrap: wrap;
    }
  }

  @media (min-width: 769px) {
    .column__aside {
      padding-right: 3rem !important;
      border-right: 1px solid ${colors.borderForm};
    }
    .column__main {
      padding-left: 3rem !important;
      .level {
        flex-wrap: wrap;
      }
    }
  }
`
