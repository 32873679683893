import React from "react"
import { css } from "@emotion/core"
import { RichText } from "prismic-reactjs"
import Image from "../components/image"

const Hero = ({ hero, children }) => {
  return (
    <header className="hero is-theme2 is-small" css={heroCss}>
      <div className="hero-body">
        <div className="container">
          <div className="columns is-vcentered is-multiline">
            <div className="column is-5-desktop is-12-tablet">
              {hero.title && (
                <h1 className="title">{RichText.asText(hero.title)}</h1>
              )}
              {hero.subtitle && (
                <h2 className="is-size-5">{RichText.asText(hero.subtitle)}</h2>
              )}
              {children}
            </div>
            <div className="column is-7-desktop is-12-tablet column__image">
              {hero.image && hero.image.image && (
                <figure className="image is-hero-size">
                  <Image sharp={hero.image.sharp} image={hero.image.image} />
                </figure>
              )}
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

Hero.propTypes = {}

export default Hero

const heroCss = css`
  .hero-body {
    .image {
      margin: 0 auto;
      position: relative;
      &.is-hero-size {
        height: 250px;
        margin-top: -65px;
      }
    }
    .gatsby-image-wrapper {
      position: absolute;
      height: 250px;
      width: 100%;
      bottom: -65px;
    }
    img {
      height: 250px !important;
      object-fit: contain !important;
    }
  }
`
