import React from "react"
import { RichText } from "prismic-reactjs"
import Image from "../components/image"
import { css } from "@emotion/core"
import { isExternalLink, isDocumentLink } from "../utils/utils"
import LocalizedLink from "../components/localizedLink"
import background from "../../static/images/trial-bg.png"

function Trial({ trial }) {
  return (
    <section
      className="section"
      css={{
        backgroundImage: `url(${background})`,
        backgroundRepeat: "no-repeat",
        marginBottom: "-80px",
        backgroundSize: "cover",
      }}
    >
      <div className="container is-fullhd" css={trialCss}>
        <div className="columns">
          <div className="column is-hidden-touch">
            {trial.image_left && (
              <Image sharp={trial.image_leftSharp} image={trial.image_left} />
            )}
          </div>
          <div className="column is-3-widescreen is-12-tablet has-text-centered">
            <h4 className="title is-size-3 has-text-weight-semibold my-6">
              {RichText.asText(trial.title)}
            </h4>
            {trial.subtitle && (
              <h5 className="subtitle is-size-5 has-text-weight-light mb-6">
                {RichText.asText(trial.subtitle)}
              </h5>
            )}
            {trial.button_link && isExternalLink(trial.button_link) && (
              <a
                className="button is-rounded button__primary has-text-white"
                href={trial.button_link.url}
              >
                {trial.button}
              </a>
            )}
            {trial.button_link && isDocumentLink(trial.button_link) && (
              <LocalizedLink
                to={trial.button_link._meta.uid}
                className="button is-primary"
              >
                {trial.button}
              </LocalizedLink>
            )}
            <div className="trial__center">
              {trial.image_bottom && (
                <Image
                  sharp={trial.image_bottomSharp}
                  image={trial.image_bottom}
                />
              )}
            </div>
          </div>
          <div className="column is-hidden-touch">
            {trial.image_right && (
              <Image sharp={trial.image_rightSharp} image={trial.image_right} />
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Trial

const trialCss = css`
  .column.is-hidden-touch {
    padding-top: 100px;
  }

  .trial__center .gatsby-image-wrapper {
    max-width: 160px;
    margin: 0 auto;
  }
`
